<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item && parent">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-package"></i>
            {{ $t("packages.label") }}
          </template>
          <form-input 
            type="select"
            label="packages.size" 
            icon="bx-expand-alt"
            rules="required"
            v-model="item.size" 
            :options="resource.sizes | transMap" 
            :clearable="false" 
            :selectable="selectable"
            :description="description"
          />
          <form-input 
            label="packages.description" 
            icon="bx-detail"
            v-model="item.description"
          />
        </b-card>
      </div>
      <div class="col mb-4">
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import { SIZE_OVERSIZED } from '@/resources/Package';
export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput },
  computed: {
    description() {
      return this.parent.$meta.pudo ? undefined : this.$t('packages.size-info')
    }
  },
  methods: {
    fill() {
      return {
        shipment_id: this.$route.params.parent_id
      }
    },
    afterSave(){
      this.$router.go(-1)
    },
    selectable(option) {
      return option.code != SIZE_OVERSIZED || this.parent.$meta.pudo
    }
  }
}
</script>